<template>
	<b-container fluid>
		<b-row>
			<b-col md="12">
				<iq-card>
					<template v-slot:headerTitle>
						<h4 class="card-title">Historial Fisioterapeutico</h4>
					</template>
					<template v-slot:headerAction>
						<b-button variant="primary" @click="showModalAdd(data)">
							Agregar
						</b-button>
					</template>
					<template v-slot:body>
						<DataTable v-if="history" :data="history" :columns="columns" :fields-search="[
							'diagnoses',
							'weight',
							'height',
							'systolic_pressure',
							'diastolic_pressure',
						]">
							<template #rows="{ row, rowIndex }">
								<tr>
									<th scope="row">{{ rowIndex + 1 }}</th>
									<td>
										{{ row.user.first_last_name }} {{ row.user.second_last_name }},
										{{ row.user.name }}
									</td>
									<td>
										{{ row.blood_pressure }}
									</td>
									<td>
										{{ row.pulse }}
									</td>
									<td>
										{{ row.breathing_frequency }}
									</td>
									<td>
										{{ row.temperature }}
									</td>
									<td>
										{{ row.reason_consultation }}
									</td>
									<td>
										{{ row.physiotherapeutic_diagnosis }}
									</td>
									<td>
										<b-button-group>
											<b-button v-if="role != 'Paciente' && lastId === row.id"
												v-user-can="'leer pacientes'" variant="warning" size="sm"
												@click="showModalEdit(row)" v-b-tooltip.hover title="Editar">
												<i class="ri-ball-pen-fill m-0"></i>
											</b-button>
											<b-button v-user-can="'leer pacientes'" variant="secondary" size="sm"
												@click="showModal(row)">
												<i class="ri-eye-fill m-0"></i>
											</b-button>
											<b-button v-user-can="'leer pacientes'" variant="success" size="sm"
												@click="downloadPdf(row)">
												<i class="ri-file-fill m-0"></i>
											</b-button>
										</b-button-group>
									</td>
								</tr>
							</template>
						</DataTable>
					</template>
				</iq-card>
			</b-col>
		</b-row>

		<b-modal id="modal-data" title="Historial Clínico" hide-footer>
			<ul>
				<li><b>Presion Arterial:</b> {{ data.blood_pressure }}</li>
				<li><b>Pulso:</b> {{ data.pulse }}</li>
				<li><b>Frecuencia Respiratoria:</b> {{ data.breathing_frequency }}</li>
				<li><b>Temperatura:</b> {{ data.temperature }}</li>
				<li><b>Diabetes:</b> {{ data.diabetes }}</li>
				<li><b>HTA:</b> {{ data.hta }}</li>
				<li><b>Enf. Reumal:</b> {{ data.reumal }}</li>
				<li><b>Cirugias:</b> {{ data.cirugias }}</li>
				<li><b>Accidentes:</b> {{ data.accidentes }}</li>
				<li><b>Alergias:</b> {{ data.alergias }}</li>
				<li><b>Fracturas:</b> {{ data.fracturas }}</li>
				<li><b>Motivo de Consulta:</b> {{ data.reason_consultation }}</li>
				<li><b>Motivo de la Consulta:</b> {{ data.reason_consultation }}</li>
				<li><b>Patologia:</b> {{ data.pathology }}</li>
				<li><b>Diagnostico Fisioterapeutico:</b> {{ data.physiotherapeutic_diagnosis }}</li>
				<li><b>Valoracion Muscular:</b> {{ data.force }}</li>
				<li><b>Sitio:</b> {{ data.site }}</li>
				<li><b>Dolor:</b> {{ data.analog_scale }}</li>
			</ul>
		</b-modal>

		<b-modal id="modal-edit" title="Historial Fisioterapeutico" hide-footer size="lg" no-close-on-backdrop>
			<ValidationObserver ref="form" v-slot="{ handleSubmit }">
				<b-form @submit.stop.prevent="handleSubmit(onSubmitFisioData)">
					<b-row>
						<b-form-group class="col-md-12" label="Nota de Evolucón Fisioterapéutica:">
							<ValidationProvider name="Nota de Evolucón Fisioterapéutica:" v-slot="{ errors }">
								<b-form-textarea v-model="data.evaluation"
									:class="errors.length > 0 ? ' is-invalid' : ''"></b-form-textarea>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<div class="col-md-12">
							<p>SIGNOS VITALES:</p>
						</div>
						<b-form-group class="col-md-6" label="Presión Arterial Sistolica:">
							<ValidationProvider name="Presión Arterial:" v-slot="{ errors }">
								<b-form-input v-model="data.blood_pressure_sistolica" type="text"
									:class="errors.length > 0 ? ' is-invalid' : ''"></b-form-input>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<b-form-group class="col-md-6" label="Presión Arterial Diastolica:">
							<ValidationProvider name="Presión Arterial Diastolica:" v-slot="{ errors }">
								<b-form-input v-model="data.blood_pressure_diastolica" type="text"
									:class="errors.length > 0 ? ' is-invalid' : ''"></b-form-input>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<b-form-group class="col-md-6" label="Pulso:">
							<ValidationProvider name="Pulso:" v-slot="{ errors }">
								<b-form-input v-model="data.pulse" type="text"
									:class="errors.length > 0 ? ' is-invalid' : ''"></b-form-input>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<b-form-group class="col-md-6" label="Frecuencia Respiratoria:">
							<ValidationProvider name="Frecuencia Respiratoria" v-slot="{ errors }">
								<b-form-input v-model="data.breathing_frequency" type="text"
									:class="errors.length > 0 ? ' is-invalid' : ''"></b-form-input>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<b-form-group class="col-md-6" label="Temperatura:">
							<ValidationProvider name="Temperatura" v-slot="{ errors }">
								<b-form-input v-model="data.temperature" type="text"
									:class="errors.length > 0 ? ' is-invalid' : ''"></b-form-input>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<hr />
						<div class="col-md-12">
							<p>ANTECEDENTES HEREDO FAMILIARES:</p>
						</div>
						<b-form-group class="col-md-6" label="Diabetes:">
							<ValidationProvider name="Diabetes" v-slot="{ errors }">
								<b-form-input v-model="data.diabetes" type="text"
									:class="errors.length > 0 ? ' is-invalid' : ''"></b-form-input>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<b-form-group class="col-md-6" label="HTA:">
							<ValidationProvider name="HTA" v-slot="{ errors }">
								<b-form-input v-model="data.hta" type="text"
									:class="errors.length > 0 ? ' is-invalid' : ''"></b-form-input>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<b-form-group class="col-md-6" label="Enf. Reumal:">
							<ValidationProvider name="Enf. Reumal" v-slot="{ errors }">
								<b-form-input v-model="data.reumal" type="text"
									:class="errors.length > 0 ? ' is-invalid' : ''"></b-form-input>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<b-form-group class="col-md-6" label="Cirugias:">
							<ValidationProvider name="Cirugias" v-slot="{ errors }">
								<b-form-input v-model="data.cirugias" type="text"
									:class="errors.length > 0 ? ' is-invalid' : ''"></b-form-input>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<b-form-group class="col-md-6" label="Accidentes:">
							<ValidationProvider name="Accidentes" v-slot="{ errors }">
								<b-form-input v-model="data.accidentes" type="text"
									:class="errors.length > 0 ? ' is-invalid' : ''"></b-form-input>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<b-form-group class="col-md-6" label="Alergias:">
							<ValidationProvider name="Alergias" v-slot="{ errors }">
								<b-form-input v-model="data.alergias" type="text"
									:class="errors.length > 0 ? ' is-invalid' : ''"></b-form-input>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<b-form-group class="col-md-6" label="Fracturas:">
							<ValidationProvider name="Fracturas" v-slot="{ errors }">
								<b-form-input v-model="data.fracturas" type="text"
									:class="errors.length > 0 ? ' is-invalid' : ''"></b-form-input>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<b-form-group class="col-md-12" label="Otros:">
							<ValidationProvider name="Otros" v-slot="{ errors }">
								<b-form-textarea v-model="data.otros_antecedentes"
									:class="errors.length > 0 ? ' is-invalid' : ''"></b-form-textarea>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<b-form-group class="col-md-12" label="Motivo de Consulta:">
							<ValidationProvider name="Motivo de Consulta" v-slot="{ errors }">
								<b-form-textarea v-model="data.reason_consultation" type="text"
									:class="errors.length > 0 ? ' is-invalid' : ''">
								</b-form-textarea>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<b-form-group class="col-md-12" label="PATOLOGÍA(ENFERMEDAD ACTUAL):">
							<ValidationProvider name="PATOLOGÍA(ENFERMEDAD ACTUAL)" v-slot="{ errors }">
								<b-form-textarea v-model="data.pathology" type="text"
									:class="errors.length > 0 ? ' is-invalid' : ''">
								</b-form-textarea>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<b-form-group class="col-md-12" label="Exploración fisica:">
							<ValidationProvider name="Exploración fisica" v-slot="{ errors }">
								<b-form-textarea v-model="data.exploracion_fisica" type="text"
									:class="errors.length > 0 ? ' is-invalid' : ''"></b-form-textarea>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<div class="col-md-12">
							<p>EVALUACIÓN FISIOTERAPÉUTICO:</p>
						</div>
						<b-form-group class="col-md-12" label="Vista Anatómica:">
							<ValidationProvider name="Vista Anatómica" v-slot="{ errors }">
								<b-form-textarea v-model="data.vista_anatomica" type="text"
									:class="errors.length > 0 ? ' is-invalid' : ''"></b-form-textarea>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<b-form-group class="col-md-6" label="Vista Anterior:">
							<ValidationProvider name="Vista Anterior" v-slot="{ errors }">
								<b-form-input v-model="data.vista_anterior" type="text"
									:class="errors.length > 0 ? ' is-invalid' : ''"></b-form-input>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<b-form-group class="col-md-6" label="Vista Posterior:">
							<ValidationProvider name="Vista Posterior" v-slot="{ errors }">
								<b-form-input v-model="data.vista_posterior" type="text"
									:class="errors.length > 0 ? ' is-invalid' : ''"></b-form-input>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<b-form-group class="col-md-6" label="Vista Lateral:">
							<ValidationProvider name="Vista Lateral" v-slot="{ errors }">
								<b-form-input v-model="data.vista_lateral" type="text"
									:class="errors.length > 0 ? ' is-invalid' : ''"></b-form-input>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<b-form-group class="col-md-6" label="Imagenes:">
							<ValidationProvider name="Imagenes" v-slot="{ errors }">
								<input type="file" multiple accept="image/*" @change="previewImages"
									:class="errors.length > 0 ? ' is-invalid' : 'mb-2'" />
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
							<div class="row" v-if="images.length > 0">
								<div v-for="(image, index) in images " :key="index" class="col-4">
									<img :src="image" alt="" class="mw-100" />
								</div>
							</div>
						</b-form-group>
						<b-form-group class="col-md-12" label="Palpación:">
							<ValidationProvider name="Palpación" v-slot="{ errors }">
								<b-form-textarea v-model="data.palpation" type="text"
									:class="errors.length > 0 ? ' is-invalid' : ''"></b-form-textarea>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<b-form-group class="col-md-6" label="Enf.Reumatica:">
							<ValidationProvider name="Enf.Reumatica" v-slot="{ errors }">
								<b-form-input v-model="data.palpation_reumatica" type="text"
									:class="errors.length > 0 ? ' is-invalid' : ''"></b-form-input>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<b-form-group class="col-md-6" label="Sitio:">
							<ValidationProvider name="Sitio" v-slot="{ errors }">
								<b-form-input v-model="data.palpation_sitio" type="text"
									:class="errors.length > 0 ? ' is-invalid' : ''"></b-form-input>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<b-form-group class="col-md-6" label="Tiempo:">
							<ValidationProvider name="Tiempo" v-slot="{ errors }">
								<b-form-input v-model="data.palpation_tiempo" type="text"
									:class="errors.length > 0 ? ' is-invalid' : ''"></b-form-input>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<b-form-group class="col-md-6" label="Movilidad:">
							<ValidationProvider name="Movilidad" v-slot="{ errors }">
								<b-form-input v-model="data.palpation_movilidad" type="text"
									:class="errors.length > 0 ? ' is-invalid' : ''"></b-form-input>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<b-form-group class="col-md-6" label="Hipotonia:">
							<ValidationProvider name="Hipotonia" v-slot="{ errors }">
								<b-form-input v-model="data.palpation_hipotonia" type="text"
									:class="errors.length > 0 ? ' is-invalid' : ''"></b-form-input>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<b-form-group class="col-md-6" label="Atrofia:">
							<ValidationProvider name="Atrofia" v-slot="{ errors }">
								<b-form-input v-model="data.palpation_atrofia" type="text"
									:class="errors.length > 0 ? ' is-invalid' : ''"></b-form-input>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<b-form-group class="col-md-6" label="Dolor:">
							<ValidationProvider name="Dolor" v-slot="{ errors }">
								<b-form-input v-model="data.palpation_dolor" type="text"
									:class="errors.length > 0 ? ' is-invalid' : ''"></b-form-input>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<div class="col-md-12">
							<p>VALORACION MUSCULAR:</p>
						</div>
						<b-form-group class="col-md-6" label-for="fuerza" label="Fuerza:">
							<b-form-select v-model="data.force" plain :options="['0', '1', '2', '3', '4', '5']"
								id="fuerza">
							</b-form-select>
						</b-form-group>
						<b-form-group class="col-md-4" label="Contractura:" label-class="d-block">
							<b-form-radio inline v-model="data.contracture" value="No">
								No
							</b-form-radio>
							<b-form-radio inline v-model="data.contracture" value="Si">
								Si
							</b-form-radio>
						</b-form-group>
						<b-form-group class="col-md-6" label="Sitio:">
							<ValidationProvider name="Sitio" v-slot="{ errors }">
								<b-form-input v-model="data.site" type="text"
									:class="errors.length > 0 ? ' is-invalid' : ''"></b-form-input>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<div class="col-md-12">
							<p>VALORACION ARTICULAR:</p>
						</div>
						<b-form-group class="col-md-6" label-for="flexion" label="Flexion Cadera:">
							<b-form-select v-model="data.hip_flexion" plain
								:options="['Izquierda', 'Derecha', 'Ninguno']" id="contractura">
							</b-form-select>
						</b-form-group>
						<b-form-group class="col-md-6" label-for="flexion_rodilla" label="Flexion Rodilla:">
							<b-form-select v-model="data.knee_flexion" plain
								:options="['Izquierda', 'Derecha', 'Ninguno']" id="contractura">
							</b-form-select>
						</b-form-group>
						<b-form-group class="col-md-6" label-for="flexion_tobillo" label="Flexion Tobillo:">
							<b-form-select v-model="data.ankle_flexion" plain
								:options="['Izquierda', 'Derecha', 'Ninguno']" id="contractura">
							</b-form-select>
						</b-form-group>
						<b-form-group class="col-md-6" label-for="extension_cadera" label="Extension Cadera:">
							<b-form-select v-model="data.hip_extension" plain
								:options="['Izquierda', 'Derecha', 'Ninguno']" id="contractura">
							</b-form-select>
						</b-form-group>
						<b-form-group class="col-md-6" label-for="extension_rodilla" label="Extension Rodilla:">
							<b-form-select v-model="data.knee_extension" plain
								:options="['Izquierda', 'Derecha', 'Ninguno']" id="contractura">
							</b-form-select>
						</b-form-group>
						<b-form-group class="col-md-6" label-for="extension_tobillo" label="Extension Tobillo:">
							<b-form-select v-model="data.ankle_extension" plain
								:options="['Izquierda', 'Derecha', 'Ninguno']" id="contractura">
							</b-form-select>
						</b-form-group>
						<b-form-group class="col-md-6" label-for="aduccion_tobillo" label="Aducción Cadera:">
							<b-form-select v-model="data.hip_adduction" plain
								:options="['Izquierda', 'Derecha', 'Ninguno']" id="contractura">
							</b-form-select>
						</b-form-group>
						<b-form-group class="col-md-6" label-for="aducction_rodilla" label="Aducción Rodilla:">
							<b-form-select v-model="data.knee_adduction" plain
								:options="['Izquierda', 'Derecha', 'Ninguno']" id="contractura">
							</b-form-select>
						</b-form-group>
						<b-form-group class="col-md-6" label-for="extension_tobillo" label="Aducción Tobillo:">
							<b-form-select v-model="data.ankle_adduction" plain
								:options="['Izquierda', 'Derecha', 'Ninguno']" id="contractura">
							</b-form-select>
						</b-form-group>
						<b-form-group class="col-md-6" label-for="abduccion_tobillo" label="Abducción Cadera:">
							<b-form-select v-model="data.hip_abduction" plain
								:options="['Izquierda', 'Derecha', 'Ninguno']" id="contractura">
							</b-form-select>
						</b-form-group>
						<b-form-group class="col-md-6" label-for="abducction_rodilla" label="Abducción Rodilla:">
							<b-form-select v-model="data.knee_abduction" plain
								:options="['Izquierda', 'Derecha', 'Ninguno']" id="contractura">
							</b-form-select>
						</b-form-group>
						<b-form-group class="col-md-6" label-for="extension_tobillo" label="Abducción Tobillo:">
							<b-form-select v-model="data.ankle_abduction" plain
								:options="['Izquierda', 'Derecha', 'Ninguno']" id="contractura">
							</b-form-select>
						</b-form-group>
						<b-form-group class="col-md-6" label-for="external_rotation_cadera"
							label="Rotación Externa Cadera:">
							<b-form-select v-model="data.hip_external_rotation" plain
								:options="['Izquierda', 'Derecha', 'Ninguno']" id="contractura">
							</b-form-select>
						</b-form-group>
						<b-form-group class="col-md-6" label-for="external_rotation_rodilla"
							label="Rotación Externa Rodilla:">
							<b-form-select v-model="data.knee_external_rotation" plain
								:options="['Izquierda', 'Derecha', 'Ninguno']" id="contractura">
							</b-form-select>
						</b-form-group>
						<b-form-group class="col-md-6" label-for="external_rotation_tobillo"
							label="Rotación Externa Tobillo:">
							<b-form-select v-model="data.ankle_external_rotation" plain
								:options="['Izquierda', 'Derecha', 'Ninguno']" id="contractura">
							</b-form-select>
						</b-form-group>
						<b-form-group class="col-md-6" label-for="internal_rotation_cadera"
							label="Rotación Interna Cadera:">
							<b-form-select v-model="data.hip_internal_rotation" plain
								:options="['Izquierda', 'Derecha', 'Ninguno']" id="contractura">
							</b-form-select>
						</b-form-group><b-form-group class="col-md-6" label-for="internal_rotation_rodilla"
							label="Rotación Interna Rodilla:">
							<b-form-select v-model="data.knee_internal_rotation" plain
								:options="['Izquierda', 'Derecha', 'Ninguno']" id="contractura">
							</b-form-select>
						</b-form-group>
						<b-form-group class="col-md-6" label-for="external_rotation_tobillo"
							label="Rotación Interna Tobillo:">
							<b-form-select v-model="data.ankle_internal_rotation" plain
								:options="['Izquierda', 'Derecha', 'Ninguno']" id="contractura">
							</b-form-select>
						</b-form-group>

						<div class="col-md-12">
							<p>Marcha:</p>
						</div>
						<b-form-group class="col-md-6" label="MARCHA:">
							<ValidationProvider name="Marcha" v-slot="{ errors }">
								<v-select :options="[
									'Libre',
									'Claudicante',
									'Con Ayuda',
									'Espástica',
									'Atáxica',
								]" v-model="data.march" :class="errors.length > 0 ? ' is-invalid' : ''" multiple />
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<b-form-group class="col-md-6" label-for="translado" label="Translado:">
							<b-form-select v-model="data.transfer" plain
								:options="['Independiente', 'Con Ayuda', 'Sillas de ruedas']" id="contractura">
							</b-form-select>
						</b-form-group>
						<b-form-group class="col-md-6" label-for="equilibrio" label="PROPIOCEPCIÓN Y EQUILIBRIO:">
							<b-form-select v-model="data.balance" plain :options="['Presente', 'Ausente', 'Torpe']"
								id="contractura">
							</b-form-select>
						</b-form-group>
						<div class="col-md-12">
							<p>Dolor:</p>
						</div>
						<b-form-group class="col-md-6" label-for="escala" label="Escala Analógica:">
							<b-form-select v-model="data.analog_scale" plain
								:options="['1', '2', '3', '4', '5', '6', '7', '8', '9', '10']" id="contractura">
							</b-form-select>
						</b-form-group>

						<b-form-group class="col-md-12" label="Diagnostico:">
							<ValidationProvider name="Diagnostico" v-slot="{ errors }">
								<b-form-textarea v-model="data.physiotherapeutic_diagnosis" type="text"
									:class="errors.length > 0 ? ' is-invalid' : ''">
								</b-form-textarea>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<div class="col-md-12">
							<p>PLAN DE TRATAMIENTO FISIOTERAPÉUTICO</p>
						</div>
						<b-form-group class="col-md-4" label="Ondas de Choque (sesiones):">
							<ValidationProvider name="Ondas de Choque (sesiones)" v-slot="{ errors }">
								<b-form-input v-model="data.shock_waves" type="number"
									:class="errors.length > 0 ? ' is-invalid' : ''"></b-form-input>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<b-form-group class="col-md-4" label="LÁSER DE ALTA INTENSIDAD (sesiones):">
							<ValidationProvider name="LÁSER DE ALTA INTENSIDAD (sesiones)" v-slot="{ errors }">
								<b-form-input v-model="data.laser" type="number"
									:class="errors.length > 0 ? ' is-invalid' : ''"></b-form-input>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<b-form-group class="col-md-4" label="PLASMA RICO EN PLAQUETAS (sesiones):">
							<ValidationProvider name="PLASMA RICO EN PLAQUETAS (sesiones)" v-slot="{ errors }">
								<b-form-input v-model="data.plasma" type="number"
									:class="errors.length > 0 ? ' is-invalid' : ''"></b-form-input>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<b-form-group class="col-md-4" label="PUNCIÓN FIT/BAK (sesiones):">
							<ValidationProvider name="PUNCIÓN FIT/BAK (sesiones)" v-slot="{ errors }">
								<b-form-input v-model="data.fit" type="number"
									:class="errors.length > 0 ? ' is-invalid' : ''"></b-form-input>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<b-form-group class="col-md-4" label="MAGNETOTERAPIA (sesiones):">
							<ValidationProvider name="MAGNETOTERAPIA (sesiones)" v-slot="{ errors }">
								<b-form-input v-model="data.magentoterapia" type="number"
									:class="errors.length > 0 ? ' is-invalid' : ''"></b-form-input>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<b-form-group class="col-md-4" label="ULTRASONIDO (sesiones):">
							<ValidationProvider name="ULTRASONIDO (sesiones)" v-slot="{ errors }">
								<b-form-input v-model="data.ultrasonido" type="number"
									:class="errors.length > 0 ? ' is-invalid' : ''"></b-form-input>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<b-form-group class="col-md-4" label="ELECTROTERAPIA (sesiones):">
							<ValidationProvider name="ELECTROTERAPIA (sesiones)" v-slot="{ errors }">
								<b-form-input v-model="data.electroterapia" type="number"
									:class="errors.length > 0 ? ' is-invalid' : ''"></b-form-input>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<b-form-group class="col-md-4" label="CAMA CERAGEM (sesiones):">
							<ValidationProvider name="CAMA CERAGEM (sesiones)" v-slot="{ errors }">
								<b-form-input v-model="data.ceragem" type="number"
									:class="errors.length > 0 ? ' is-invalid' : ''"></b-form-input>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<b-form-group class="col-md-4" label="C.P.M. (sesiones):">
							<ValidationProvider name="C.P.M. (sesiones)" v-slot="{ errors }">
								<b-form-input v-model="data.cpm" type="number"
									:class="errors.length > 0 ? ' is-invalid' : ''"></b-form-input>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<b-form-group class="col-md-4" label="ELECTROPUNCIÓN (sesiones):">
							<ValidationProvider name="ELECTROPUNCIÓN (sesiones)" v-slot="{ errors }">
								<b-form-input v-model="data.electropuncion" type="number"
									:class="errors.length > 0 ? ' is-invalid' : ''"></b-form-input>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<b-form-group class="col-md-4" label="FORTALECIMIENTO MUSCULAR (sesiones):">
							<ValidationProvider name="FORTALECIMIENTO MUSCULAR (sesiones)" v-slot="{ errors }">
								<b-form-input v-model="data.muscle" type="number"
									:class="errors.length > 0 ? ' is-invalid' : ''"></b-form-input>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<b-form-group class="col-md-4" label="ACONDICIONAMIENTO FÍSICO (sesiones):">
							<ValidationProvider name="ACONDICIONAMIENTO FÍSICO (sesiones)" v-slot="{ errors }">
								<b-form-input v-model="data.acondicionamiento" type="number"
									:class="errors.length > 0 ? ' is-invalid' : ''"></b-form-input>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<b-form-group class="col-md-4" label="GIMNASIO TERAPÉUTICO (sesiones):">
							<ValidationProvider name="GIMNASIO TERAPÉUTICO (sesiones)" v-slot="{ errors }">
								<b-form-input v-model="data.gimnacio" type="number"
									:class="errors.length > 0 ? ' is-invalid' : ''"></b-form-input>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<b-form-group class="col-md-4" label="MASAJE DE DESCARGA MUSCULAR (sesiones):">
							<ValidationProvider name="MASAJE DE DESCARGA MUSCULAR (sesiones)" v-slot="{ errors }">
								<b-form-input v-model="data.muscle_discharge" type="number"
									:class="errors.length > 0 ? ' is-invalid' : ''"></b-form-input>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<b-form-group class="col-md-4" label="MASAJE CYRIAX (sesiones):">
							<ValidationProvider name="MASAJE CYRIAX (sesiones)" v-slot="{ errors }">
								<b-form-input v-model="data.cyriax" type="number"
									:class="errors.length > 0 ? ' is-invalid' : ''"></b-form-input>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<b-form-group class="col-md-4" label="MASAJE ANTIESTRÉS (sesiones):">
							<ValidationProvider name="MASAJE ANTIESTRÉS (sesiones)" v-slot="{ errors }">
								<b-form-input v-model="data.antiestres" type="number"
									:class="errors.length > 0 ? ' is-invalid' : ''"></b-form-input>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<b-form-group class="col-md-4" label="MASAJE DESCONTRACTURANTE (sesiones):">
							<ValidationProvider name="MASAJE DESCONTRACTURANTE (sesiones)" v-slot="{ errors }">
								<b-form-input v-model="data.descontracturante" type="number"
									:class="errors.length > 0 ? ' is-invalid' : ''"></b-form-input>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<b-form-group class="col-md-4" label="KINESIOTAPING (sesiones):">
							<ValidationProvider name="KINESIOTAPING (sesiones)" v-slot="{ errors }">
								<b-form-input v-model="data.kinesiotaping" type="number"
									:class="errors.length > 0 ? ' is-invalid' : ''"></b-form-input>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<b-form-group class="col-md-4" label="SUERO TERAPIA (sesiones):">
							<ValidationProvider name="SUERO TERAPIA (sesiones)" v-slot="{ errors }">
								<b-form-input v-model="data.suero" type="number"
									:class="errors.length > 0 ? ' is-invalid' : ''"></b-form-input>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<b-form-group class="col-md-4" label="PRESOTERAPIA (sesiones):">
							<ValidationProvider name="PRESOTERAPIA (sesiones)" v-slot="{ errors }">
								<b-form-input v-model="data.preso" type="number"
									:class="errors.length > 0 ? ' is-invalid' : ''"></b-form-input>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
					</b-row>
					<b-button type="submit" variant="primary" class="mr-2"> Guardar </b-button>
					<b-button type="reset" variant="none" class="iq-bg-danger"> Cancelar </b-button>
				</b-form>
			</ValidationObserver>
		</b-modal>
	</b-container>
</template>

<script>
import { Toast } from "./../../../mixins/toast";
import { xray } from "../../../config/pluginInit";
import api from "./../../../services/api";
import DataTable from "./../../../components/DataTable.vue";
import Swal from "sweetalert2";

export default {
	components: {
		DataTable,
	},
	data() {
		return {
			role: null,
			data: {},
			type: "add",
			history: [],
			columns: [
				{
					name: "Paciente",
					field: "name",
					sort: "",
				},
				{
					name: "Presion Arterial",
					field: "blood_pressure",
					sort: "",
				},
				{
					name: "Pulso",
					field: "pulse",
					sort: "",
				},
				{
					name: "Frecuencia Respiratoria",
					field: "breathing_frequency",
					sort: "",
				},
				{
					name: "Temperatura",
					field: "systolic_pressure",
					sort: "",
				},
				{
					name: "Motivo de Consulta",
					field: "diastolic_pressure",
					sort: "",
				},
				{
					name: "Diagnostico",
					field: "created_at",
					sort: "",
				},
				{
					name: "Acciones",
					field: "actions",
					sort: "",
				},
			],
			lastId: null,
			type: "add",
			images: [],
			files: [],
		};
	},
	mounted() {
		xray.index();
		this.getData();
		let user = JSON.parse(localStorage.getItem("user"));
		this.role = user.role;
	},
	methods: {
		previewImages(event) {
			const files = event.target.files
			this.files = files

			if (files && files.length > 0) {
				this.images = [];
				Array.from(files).forEach(file => {
					const lector = new FileReader();
					lector.onload = (e) => {
						this.images.push(e.target.result)
					}
					lector.readAsDataURL(file)
				})
			}
		},
		async getData() {
			try {
				const { data } = await api.get(
					`/physioterapeutic-history?patient_id=${this.$route.params.id}`
				);
				this.history = data.data;
				this.lastId = this.history ? this.history[0].id : null;
			} catch (error) {
				Toast.fire({
					icon: "error",
					title: "Error",
				});
			}
		},
		showModal(data) {
			this.data = data;
			this.$bvModal.show("modal-data");
		},
		showModalAdd(data) {
			this.data = {}
			this.type = "add";
			this.$bvModal.show("modal-edit");
		},
		showModalEdit(data) {
			this.data = data;
			this.type = "edit";
			this.$bvModal.show("modal-edit");
		},
		destroy(id) {
			Swal.fire({
				icon: "warning",
				title: "Seguro de eliminar",
				showDenyButton: false,
				showCancelButton: true,
				confirmButtonText: "Si",
				cancelButtonText: "Cancelar",
			}).then((result) => {
				if (result.isConfirmed) {
					api
						.delete(`/medical-history/${id}`)
						.then((res) => {
							if (res.status === 200) {
								Toast.fire({
									icon: "success",
									title: "Eliminado correctamente",
								});
								this.getData();
							}
						})
						.catch((error) => {
							Toast.fire({
								icon: "error",
								title: error.message,
							});
						});
				}
			});
		},
		async onSubmitFisioData() {
			const url = `/patients/${this.$route.params.id}/fisio`;

			const formData = new FormData()

			for (let i = 0; i < this.files.length; i++) {
				console.log(this.files[i])
				formData.append('images[]', this.files[i]);
			}

			console.log(this.data);

			try {
				let response;
				if (this.type === "add") {
					response = await api.post(url, this.data);
				} else {
					response = await api.put(url, this.data);
				}
				if (response) {
					await api.post(`/patients/${this.$route.params.id}/fisio/images`, formData);
				}
				await this.getData();
				this.$bvModal.hide("modal-edit");
			} catch (error) {
				console.log("error");
				console.log(error);
				Toast.fire({
					icon: "error",
					title: "Error",
				});
			}
		},
		async downloadPdf(row) {
			const url = `/patients/${this.$route.params.id}/fisio/pdf`;
			try {
				const response = await api.post(url, { row }, { responseType: 'blob' });
				const urlPDF = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = urlPDF;
				link.setAttribute('download', 'historial-fisioterapeutico.pdf'); // Nombre del archivo para descarga
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
				Toast.fire({
					icon: "success",
					title: "PDF"
				})
			} catch (e) {
				Toast.fire({
					icon: "error",
					title: "Error"
				})
			}
		}
	},
};
</script>
